
import {Component, Vue, Watch} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import {
  applications,
  orders,
  shops,
  applicationOrderRequests,
  userViews,
  shopOrderRequests,
} from '../../../../resources';
import {ObjectId} from 'bson';
import {
  Order,
  MallOrderStatus,
  AgentOrderStatus,
  WithdrawalOrderStatus,
  UsedOrderStatus,
  RechargeOrderStatus,
} from '@/externals/MaxCI-Order-v1';
import moment from 'moment';
import lodash from 'lodash';
import xlsx from 'xlsx';
import {EJSON} from 'bson';
import {Shop} from '@/externals/MaxCI-Shop-v1';

interface OrderShop extends Order {
  goodsMore: boolean;
}
@Component({
  name: 'orderList',
  components: {
    multiSelect,
    Pagination,
  },
})
export default class extends Vue {
  private cancelData = {
    reason: '',
    remark: '',
  };
  private dialogDelivery = false; //发起配送弹窗
  private shipText = ''; //快递单号
  private dialogShip = false; //发货弹窗
  private loading = false;
  private orderTypeData: Array<string> = []; //订单类型数据
  private operateId: null | ObjectId = null;
  private operateData: null | OrderShop = null;
  private dialogRequest = false; //取消弹窗
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {}; //店铺信息
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private userInfoData: any = {}; //用户信息
  private total = 0;
  private list: Array<OrderShop> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private time = '今';
  private searchData = {
    id: '',
    status: '',
    type: '',
    deliveryInfo: '',
    payMerchantType: '',
    shopId: '',
    phone: '',
    time: [moment().startOf('day').toDate(), moment().startOf('day').toDate()],
  };
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';
  private shopSearchData = [] as Array<Shop>;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );

  async created() {
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }
    //概况跳转后回显搜索条件
    if (this.$route.query.orderStatus) {
      this.searchData.status = this.$route.query.orderStatus
        ? (this.$route.query.orderStatus as string)
        : '';
      if (this.searchData.status) {
        this.searchData.time = [];
      }
    }

    if (this.role === '应用') {
      //店铺搜索查询所有店铺
      const shopSearchData = [] as Array<Shop>;
      const shopCount = await shops.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet =>
            facet('count', countStage => countStage.$count('count')),
          ),
      );
      const count = shopCount[0].count[0]
        ? shopCount[0].count[0].count.valueOf()
        : 0;
      let i = 0;
      while (i < Math.ceil(count / 100)) {
        i++;
        const list = await shops.find(stage =>
          stage
            .$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              );
              return match;
            })
            .$facet(facet =>
              facet('table', tableStage =>
                tableStage
                  .$sort(sort => sort(f => f('_id'), '降序'))
                  .$skip((i - 1) * 100)
                  .$limit(100),
              ),
            ),
        );
        shopSearchData.push(...list[0].table);
      }
      this.shopSearchData = shopSearchData;
    } else {
      //店铺搜索查询所有店铺
      this.shopSearchData = await shops.find(stage =>
        stage.$match(match => {
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('_id'),
            e => e.$in(this.shopAuthority),
          );
          return match;
        }),
      );
    }

    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          );
          return match;
        }),
      )
    ).find(() => true);
    if (application?.spec.enableServices) {
      application?.spec.enableServices.forEach(item => {
        if (item === '商城') {
          this.orderTypeData.push('商城');
        } else if (item === '跑腿') {
          this.orderTypeData.push('跑腿');
        } else if (item === '二手') {
          this.orderTypeData.push('二手');
        } else if (item === '会员卡') {
          this.orderTypeData.push('会员卡');
        }
      });
    }
    this.updateList();
  }
  @Watch('dialogShip')
  private changedialogShip() {
    if (!this.dialogShip) {
      this.shipText = '';
    }
  }
  //发起配送保存
  private deliverySumbit() {
    if (this.operateData && this.operateData.spec.type === '商城') {
      shopOrderRequests
        .create(
          [
            {
              spec: {
                type: '配送',
                shopId: this.operateData.spec.shopId as ObjectId,
                applicationId: ObjectId.createFromHexString(
                  this.$route.params.applicationId,
                ),
                channelId: this.operateData.spec.channelId as ObjectId,
                orderId: this.operateData._id as ObjectId,
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        )
        .then(res => {
          if (res[0].status?.phase === '成功') {
            this.$message.success('操作成功');
            this.updateList();
            this.dialogDelivery = false;
          } else {
            this.$message.error(res[0].status?.conditions[0].message ?? '');
          }
        });
    }
  }
  //发起配送弹窗
  private deliveryPopUps(data: OrderShop) {
    this.operateData = data;
    this.dialogDelivery = true;
  }
  //发货弹窗
  private shipPopUps(data: OrderShop) {
    this.operateData = data;
    this.dialogShip = true;
  }
  //发货保存
  private shiptSumbit() {
    if (this.shipText === '') {
      this.$message.error('请先填写快递单号');
    } else {
      if (this.operateData && this.operateData.spec.type === '商城') {
        shopOrderRequests
          .create(
            [
              {
                spec: {
                  type: '配送',
                  expressNumber: this.shipText,
                  shopId: this.operateData.spec.shopId as ObjectId,
                  applicationId: ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                  channelId: this.operateData.spec.channelId as ObjectId,
                  orderId: this.operateData._id as ObjectId,
                },
              },
            ],
            {
              watch: {
                filter: filter =>
                  filter(
                    f => f('operationType'),
                    e => e.$eq('update'),
                  )(
                    f => f('fullDocument')('status')('phase'),
                    e => e.$exists(true),
                  ),
              },
              fullResource: true,
            },
          )
          .then(res => {
            if (res[0].status?.phase === '成功') {
              this.$message.success('操作成功');
              this.updateList();
              this.dialogShip = false;
            } else {
              this.$message.error(res[0].status?.conditions[0].message ?? '');
            }
          });
      }
    }
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //取消弹窗
  private requestPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogRequest = true;
  }
  //取消保存
  private requestSumbit() {
    let reason = '';
    if (!this.cancelData.reason) {
      this.$message.error('请选择取消原因');
      return false;
    }
    if (this.cancelData.reason === '其他原因') {
      if (!this.cancelData.remark) {
        this.$message.error('请填写原因');
        return false;
      } else {
        reason = this.cancelData.remark;
      }
    } else {
      reason = this.cancelData.reason;
    }
    applicationOrderRequests
      .create(
        [
          {
            spec: {
              type: '取消',
              applicationId: ObjectId.createFromHexString(
                this.$route.params.applicationId,
              ),
              reason: reason,
              orderId: this.operateId as ObjectId,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res[0].status?.phase === '成功') {
          this.$message.success('操作成功');
          this.updateList();
          this.dialogRequest = false;
        } else {
          this.$message.error(res[0].status?.conditions[0].message ?? '');
        }
      });
  }
  //获取店铺信息
  private getShopInfo(id: ObjectId, type: string) {
    let text = '';
    if (this.shopInfoData[id.toHexString()]) {
      if (type === 'name') {
        text = this.shopInfoData[id.toHexString()].spec.name;
      } else if (type === 'phone') {
        text = this.shopInfoData[id.toHexString()].spec.phone;
      }
    }
    return text;
  }
  //获取用户信息
  private getUserInfo(id: ObjectId) {
    let name = '';
    if (this.userInfoData[id.toHexString()]) {
      name = this.userInfoData[id.toHexString()].spec.name;
    }
    return name;
  }
  //跳转用户详情
  private to_user(id: ObjectId) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push(
      `/application/${ObjectId.createFromHexString(
        this.$route.params.applicationId,
      )}/user/detail/${id.toHexString()}`,
    );
  }
  //商品查看更多
  private moreBtn(index: number) {
    this.list[index].goodsMore = !this.list[index].goodsMore;
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkList();
  }
  //选择今天明天
  private changeTime() {
    if (this.time === '今') {
      this.searchData.time = [
        moment().startOf('day').toDate(),
        moment().startOf('day').toDate(),
      ];
    } else if (this.time === '昨') {
      this.searchData.time = [
        moment().startOf('day').subtract({day: 1}).toDate(),
        moment().startOf('day').subtract({day: 1}).toDate(),
      ];
    }
    this.checkList();
  }
  //获取订单状态
  private getStatus(
    status: MallOrderStatus | AgentOrderStatus | WithdrawalOrderStatus,
  ) {
    let statusText = '';
    if (status.phase === '确认') {
      statusText = '待支付';
    } else if (status.phase === '支付' || status.phase === '接单') {
      statusText = '待发货';
    } else if (status.phase === '取消') {
      statusText = '已取消';
    } else if (status.phase === '完成') {
      statusText = '已完成';
    } else if (status.phase === '退款') {
      statusText = '已退款';
    } else if (status.phase === '结算') {
      statusText = '已结算';
    } else {
      statusText = '待收货';
    }
    return statusText;
  }
  //获取充值订单状态
  private rechargeGetStatus(status: RechargeOrderStatus) {
    let statusText = '';
    if (status.phase === '下单') {
      statusText = '订单待支付';
    } else if (status.phase === '支付') {
      statusText = '待充值';
    } else if (status.phase === '取消') {
      statusText = '已取消';
    } else if (status.phase === '完成') {
      statusText = '已完成';
    } else if (status.phase === '退款') {
      statusText = '已退款';
    } else {
      statusText = '充值中';
    }
    return statusText;
  }
  //获取订单状态
  private getUsedStatus(status: UsedOrderStatus) {
    let statusText = '';
    if (status.phase === '下单') {
      statusText = '待支付';
    } else if (status.phase === '支付') {
      statusText = '待发货';
    } else if (status.phase === '取消') {
      statusText = '已取消';
    } else if (status.phase === '完成') {
      statusText = '已完成';
    } else if (status.phase === '退款') {
      statusText = '已退款';
    } else {
      statusText = '待收货';
    }
    return statusText;
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //查看详情
  private toDetail(id: ObjectId) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                if (this.searchData.id) {
                  query(
                    f => f('_id'),
                    e =>
                      e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                  );
                }
                if (this.searchData.status === '') {
                  query(
                    f => f('status')('phase'),
                    e =>
                      e.$in([
                        '确认',
                        '支付',
                        '取消',
                        '接单',
                        '打包',
                        '配送',
                        '完成',
                        '退款',
                        '充值',
                        '结算',
                      ]),
                  );
                } else if (this.searchData.status === '待发货') {
                  query(
                    f => f('status')('phase'),
                    e => e.$in(['支付', '接单']),
                  );
                } else if (this.searchData.status === '待收货') {
                  query(
                    f => f('status')('phase'),
                    e => e.$in(['打包', '配送']),
                  );
                } else {
                  query(
                    f => f('status')('phase'),
                    e =>
                      e.$eq(
                        this.searchData.status as
                          | '下单'
                          | '确认'
                          | '支付'
                          | '取消'
                          | '接单'
                          | '打包'
                          | '配送'
                          | '完成'
                          | '退款'
                          | '充值'
                          | '结算',
                      ),
                  );
                }
                if (this.searchData.type) {
                  query(
                    f => f('spec')('type'),
                    e =>
                      e.$eq(
                        this.searchData.type as
                          | '商城'
                          | '跑腿'
                          | '二手'
                          | '提现',
                      ),
                  );
                }
                if (this.searchData.deliveryInfo === '自取') {
                  query(
                    f => f('spec')('receiveInfo')('type'),
                    e => e.$eq('自取'),
                  );
                } else if (
                  this.searchData.deliveryInfo === '应用配送' ||
                  this.searchData.deliveryInfo === '商家自配'
                ) {
                  query(
                    f => f('spec')('receiveInfo')('service'),
                    e =>
                      e.$eq(
                        this.searchData.deliveryInfo === '应用配送'
                          ? '应用'
                          : '店铺',
                      ),
                  );
                }
                if (this.searchData.payMerchantType) {
                  query(
                    f => f('status')('payInfo')('payMerchantType'),
                    e => e.$eq(this.searchData.payMerchantType as '微信'),
                  );
                }
                if (this.searchData.shopId) {
                  match(
                    f => f('spec')('shopId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(this.searchData.shopId),
                      ),
                  );
                } else {
                  if (this.role === '店铺' || this.role === '财务') {
                    match(
                      f => f('spec')('shopId'),
                      e => e.$in(this.shopAuthority),
                    );
                  }
                }
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                );
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e => e.$gte(moment(this.searchData.time[0]).toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1])
                          .add({day: 1})
                          .subtract({millisecond: 1})
                          .toDate(),
                      ),
                  ),
                );
              }
              if (this.searchData.phone) {
                and(query =>
                  query.$or(or => {
                    or(query =>
                      query(
                        f => f('spec')('deliveryInfo')('phone'),
                        e => e.$eq(this.searchData.phone),
                      ),
                    );
                    or(query =>
                      query(
                        f => f('spec')('receiveInfo')('info')('phone'),
                        e => e.$eq(this.searchData.phone),
                      ),
                    );
                    or(query =>
                      query(
                        f => f('spec')('phone'),
                        e => e.$eq(this.searchData.phone),
                      ),
                    );
                    return or;
                  }),
                );
              }
              and(query =>
                query.$or(or => {
                  or(query =>
                    query(
                      f => f('spec')('type'),
                      e => e.$eq('商城'),
                    ),
                  );
                  or(query =>
                    query(
                      f => f('spec')('type'),
                      e => e.$eq('跑腿'),
                    ),
                  );
                  or(query =>
                    query(
                      f => f('spec')('type'),
                      e => e.$eq('二手'),
                    ),
                  );
                  or(query =>
                    query(
                      f => f('spec')('type'),
                      e => e.$eq('会员卡'),
                    ),
                  );
                  or(query =>
                    query(
                      f => f('spec')('type'),
                      e => e.$eq('充值'),
                    ),
                  );
                  return or;
                }),
              );
              return and;
            }),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      //查询店铺信息 用户信息
      let shopIdData: Array<ObjectId> = [];
      let userIdData: Array<ObjectId> = [];
      list[0].table.forEach(item => {
        if (item.spec.type === '商城' && item.spec.shopId) {
          shopIdData.push(item.spec.shopId);
        }
        userIdData.push(item.spec.userId);
      });
      //查询店铺信息
      shopIdData = Array.from(new Set(shopIdData));
      const shopData = await shops.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$in(shopIdData),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        ),
      );
      this.shopInfoData = lodash.zipObject(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        shopData.map(v => v._id.toHexString()) as any,
        shopData,
      );
      //用户信息
      userIdData = Array.from(new Set(userIdData));
      const userData = await userViews.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$in(userIdData),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        ),
      );
      this.userInfoData = lodash.zipObject(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        userData.map(v => v._id.toHexString()) as any,
        userData,
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table.map(v => {
        return {
          ...v,
          goodsMore: false,
        };
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //商品属性值显示
  private getAttributes(attr: Array<string>) {
    let val = '';
    if (attr && attr.length > 0) {
      val = '/' + attr.join('/');
    }
    return val;
  }
  // 导出报表
  private async exportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      const count =
        (
          await orders.find(stage =>
            stage
              .$match(match =>
                match.$and(and => {
                  and(query => {
                    if (this.searchData.id) {
                      query(
                        f => f('_id'),
                        e =>
                          e.$eq(
                            ObjectId.createFromHexString(this.searchData.id),
                          ),
                      );
                    }
                    if (this.searchData.status === '') {
                      query(
                        f => f('status')('phase'),
                        e =>
                          e.$in([
                            '确认',
                            '支付',
                            '取消',
                            '接单',
                            '打包',
                            '配送',
                            '完成',
                            '退款',
                            '充值',
                            '结算',
                          ]),
                      );
                    } else if (this.searchData.status === '待发货') {
                      query(
                        f => f('status')('phase'),
                        e => e.$in(['支付', '接单']),
                      );
                    } else if (this.searchData.status === '待收货') {
                      query(
                        f => f('status')('phase'),
                        e => e.$in(['打包', '配送']),
                      );
                    } else {
                      query(
                        f => f('status')('phase'),
                        e =>
                          e.$eq(
                            this.searchData.status as
                              | '下单'
                              | '确认'
                              | '支付'
                              | '取消'
                              | '接单'
                              | '打包'
                              | '配送'
                              | '完成'
                              | '退款'
                              | '充值'
                              | '结算',
                          ),
                      );
                    }
                    if (this.searchData.type) {
                      query(
                        f => f('spec')('type'),
                        e =>
                          e.$eq(
                            this.searchData.type as
                              | '商城'
                              | '跑腿'
                              | '二手'
                              | '提现',
                          ),
                      );
                    }
                    if (this.searchData.deliveryInfo === '自取') {
                      query(
                        f => f('spec')('receiveInfo')('type'),
                        e => e.$eq('自取'),
                      );
                    } else if (
                      this.searchData.deliveryInfo === '应用配送' ||
                      this.searchData.deliveryInfo === '商家自配'
                    ) {
                      query(
                        f => f('spec')('receiveInfo')('service'),
                        e =>
                          e.$eq(
                            this.searchData.deliveryInfo === '应用配送'
                              ? '应用'
                              : '店铺',
                          ),
                      );
                    }
                    if (this.searchData.payMerchantType) {
                      query(
                        f => f('status')('payInfo')('payMerchantType'),
                        e => e.$eq(this.searchData.payMerchantType as '微信'),
                      );
                    }
                    if (this.searchData.shopId) {
                      match(
                        f => f('spec')('shopId'),
                        e =>
                          e.$eq(
                            ObjectId.createFromHexString(
                              this.searchData.shopId,
                            ),
                          ),
                      );
                    } else {
                      if (this.role === '店铺' || this.role === '财务') {
                        match(
                          f => f('spec')('shopId'),
                          e => e.$in(this.shopAuthority),
                        );
                      }
                    }
                    query(
                      f => f('spec')('applicationId'),
                      e => e.$eq(this.applicationId),
                    );
                    return query;
                  });
                  if (this.searchData.time && this.searchData.time.length > 0) {
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e => e.$gte(moment(this.searchData.time[0]).toDate()),
                      ),
                    );
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e =>
                          e.$lte(
                            moment(this.searchData.time[1])
                              .add({day: 1})
                              .subtract({millisecond: 1})
                              .toDate(),
                          ),
                      ),
                    );
                  }
                  if (this.searchData.phone) {
                    and(query =>
                      query.$or(or => {
                        or(query =>
                          query(
                            f => f('spec')('deliveryInfo')('phone'),
                            e => e.$eq(this.searchData.phone),
                          ),
                        );
                        or(query =>
                          query(
                            f => f('spec')('receiveInfo')('info')('phone'),
                            e => e.$eq(this.searchData.phone),
                          ),
                        );
                        or(query =>
                          query(
                            f => f('spec')('phone'),
                            e => e.$eq(this.searchData.phone),
                          ),
                        );
                        return or;
                      }),
                    );
                  }
                  and(query =>
                    query.$or(or => {
                      or(query =>
                        query(
                          f => f('spec')('type'),
                          e => e.$eq('商城'),
                        ),
                      );
                      or(query =>
                        query(
                          f => f('spec')('type'),
                          e => e.$eq('跑腿'),
                        ),
                      );
                      or(query =>
                        query(
                          f => f('spec')('type'),
                          e => e.$eq('二手'),
                        ),
                      );
                      or(query =>
                        query(
                          f => f('spec')('type'),
                          e => e.$eq('会员卡'),
                        ),
                      );
                      or(query =>
                        query(
                          f => f('spec')('type'),
                          e => e.$eq('充值'),
                        ),
                      );
                      return or;
                    }),
                  );
                  return and;
                }),
              )
              .$facet(facet =>
                facet('count', countStage => countStage.$count('count')),
              ),
          )
        )[0].count[0]?.count.valueOf() ?? 0;
      if (count === 0) {
        this.$message.error('暂无数据');
        return;
      }
      reportList.push([
        '订单编号',
        '店铺名称',
        '订单内容',
        '商品件数',
        '订单类型',
        '订单状态',
        '支付方式',
        '配送方式',
        '买家',
        '收货人',
        '联系方式',
        '收货地址',
        '详细地址',
        '备注',
        '商品总金额(￥)',
        '商品成本价(￥)',
        '包装费用(￥)',
        '配送费用(￥)',
        '订单优惠金额(￥)',
        '优惠券优惠金额(￥)',
        '满减优惠金额(￥)',
        '订单实付金额(￥)',
        '订单退款金额(￥)',
        '期望时间',
        '下单时间',
        '支付时间',
        '完成时间',
      ]);
      let i = 0;
      while (i < Math.ceil(count / 100)) {
        i++;
        const list = await orders.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  if (this.searchData.id) {
                    query(
                      f => f('_id'),
                      e =>
                        e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                    );
                  }
                  if (this.searchData.status === '') {
                    query(
                      f => f('status')('phase'),
                      e =>
                        e.$in([
                          '确认',
                          '支付',
                          '取消',
                          '接单',
                          '打包',
                          '配送',
                          '完成',
                          '退款',
                          '充值',
                          '结算',
                        ]),
                    );
                  } else if (this.searchData.status === '待发货') {
                    query(
                      f => f('status')('phase'),
                      e => e.$in(['支付', '接单']),
                    );
                  } else if (this.searchData.status === '待收货') {
                    query(
                      f => f('status')('phase'),
                      e => e.$in(['打包', '配送']),
                    );
                  } else {
                    query(
                      f => f('status')('phase'),
                      e =>
                        e.$eq(
                          this.searchData.status as
                            | '下单'
                            | '确认'
                            | '支付'
                            | '取消'
                            | '接单'
                            | '打包'
                            | '配送'
                            | '完成'
                            | '退款'
                            | '充值'
                            | '结算',
                        ),
                    );
                  }
                  if (this.searchData.type) {
                    query(
                      f => f('spec')('type'),
                      e =>
                        e.$eq(
                          this.searchData.type as
                            | '商城'
                            | '跑腿'
                            | '二手'
                            | '提现',
                        ),
                    );
                  }
                  if (this.searchData.deliveryInfo === '自取') {
                    query(
                      f => f('spec')('receiveInfo')('type'),
                      e => e.$eq('自取'),
                    );
                  } else if (
                    this.searchData.deliveryInfo === '应用配送' ||
                    this.searchData.deliveryInfo === '商家自配'
                  ) {
                    query(
                      f => f('spec')('receiveInfo')('service'),
                      e =>
                        e.$eq(
                          this.searchData.deliveryInfo === '应用配送'
                            ? '应用'
                            : '店铺',
                        ),
                    );
                  }
                  if (this.searchData.payMerchantType) {
                    query(
                      f => f('status')('payInfo')('payMerchantType'),
                      e => e.$eq(this.searchData.payMerchantType as '微信'),
                    );
                  }
                  if (this.searchData.shopId) {
                    match(
                      f => f('spec')('shopId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(this.searchData.shopId),
                        ),
                    );
                  } else {
                    if (this.role === '店铺' || this.role === '财务') {
                      match(
                        f => f('spec')('shopId'),
                        e => e.$in(this.shopAuthority),
                      );
                    }
                  }
                  query(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  );
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e => e.$gte(moment(this.searchData.time[0]).toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1])
                            .add({day: 1})
                            .subtract({millisecond: 1})
                            .toDate(),
                        ),
                    ),
                  );
                }
                if (this.searchData.phone) {
                  and(query =>
                    query.$or(or => {
                      or(query =>
                        query(
                          f => f('spec')('deliveryInfo')('phone'),
                          e => e.$eq(this.searchData.phone),
                        ),
                      );
                      or(query =>
                        query(
                          f => f('spec')('receiveInfo')('info')('phone'),
                          e => e.$eq(this.searchData.phone),
                        ),
                      );
                      or(query =>
                        query(
                          f => f('spec')('phone'),
                          e => e.$eq(this.searchData.phone),
                        ),
                      );
                      return or;
                    }),
                  );
                }
                and(query =>
                  query.$or(or => {
                    or(query =>
                      query(
                        f => f('spec')('type'),
                        e => e.$eq('商城'),
                      ),
                    );
                    or(query =>
                      query(
                        f => f('spec')('type'),
                        e => e.$eq('跑腿'),
                      ),
                    );
                    or(query =>
                      query(
                        f => f('spec')('type'),
                        e => e.$eq('二手'),
                      ),
                    );
                    or(query =>
                      query(
                        f => f('spec')('type'),
                        e => e.$eq('会员卡'),
                      ),
                    );
                    or(query =>
                      query(
                        f => f('spec')('type'),
                        e => e.$eq('充值'),
                      ),
                    );
                    return or;
                  }),
                );
                return and;
              }),
            )
            .$facet(facet =>
              facet('table', tableStage =>
                tableStage
                  .$sort(sort =>
                    sort(f => f('metadata')('creationTimestamp'), '降序'),
                  )
                  .$skip((i - 1) * 100)
                  .$limit(100),
              )('count', countStage => countStage.$count('count')),
            ),
        );
        //获取店铺信息 买家信息
        let shopIdData: Array<ObjectId> = [];
        let userIdData: Array<ObjectId> = [];
        list[0].table.forEach(item => {
          if (item.spec.type === '商城') {
            shopIdData.push(item.spec.shopId);
          }
          userIdData.push(item.spec.userId);
        });
        //获取店铺信息
        shopIdData = Array.from(new Set(shopIdData));
        const shopData = await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$in(shopIdData),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        );
        const shopInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          shopData.map(v => v._id.toHexString()) as any,
          shopData,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;

        //买家信息
        userIdData = Array.from(new Set(userIdData));
        const userData = await userViews.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$in(userIdData),
            )(
              f => f('spec')('applicationId'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            ),
          ),
        );
        const userInfoData = lodash.zipObject(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          userData.map(v => v._id.toHexString()) as any,
          userData,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;

        list[0].table.forEach(item => {
          //状态
          const status = item.status as
            | UsedOrderStatus
            | MallOrderStatus
            | AgentOrderStatus;
          //商品件数 期望时间 订单内容
          const orderContent = [] as Array<string>;
          let count = 0;
          let expectTime = '';
          if (item.spec.type === '商城') {
            if (item.spec.items) {
              item.spec.items.forEach(val => {
                orderContent.push(val.snapshot.name);
                count = count + val.count;
              });
            }
            if (item.spec.receiveInfo?.expectTime) {
              expectTime = this.getTime(item.spec.receiveInfo?.expectTime);
            } else {
              expectTime = '尽快送达';
            }
          }
          //支付时间 完成时间
          let payTime = '';
          let complete = '';
          item.status?.conditions.forEach(v => {
            if (v.type === '支付') {
              payTime = this.getTime(v.creationTimestamp);
            }
            if (v.type === '完成') {
              complete = this.getTime(v.creationTimestamp);
            }
          });
          //收货人 联系方式  详细地址
          let contact = '',
            phone = '',
            address = '',
            detailAddress = '';
          if (item.spec.type === '商城') {
            if (item.spec.receiveInfo?.type === '配送') {
              contact = item.spec.receiveInfo?.info.contact ?? '';
              address = item.spec.receiveInfo?.info.address ?? '';
              phone = item.spec.receiveInfo?.info?.phone ?? '';
              detailAddress = item.spec.receiveInfo?.info.details ?? '';
            } else if (item.spec.receiveInfo?.type === '自取') {
              phone = item.spec.receiveInfo?.info?.phone ?? '';
            }
          } else if (item.spec.type === '二手') {
            contact = item.spec.deliveryInfo?.contact ?? '';
            phone = item.spec.deliveryInfo?.phone;
            address = item.spec.deliveryInfo?.address;
            detailAddress = item.spec.deliveryInfo?.details ?? '';
          } else if (
            item.spec.type === '跑腿' &&
            (item.spec.agentType === '代取' ||
              item.spec.agentType === '代送' ||
              item.spec.agentType === '代购')
          ) {
            contact = item.spec.targetInfo?.contact ?? '';
            phone = item.spec.targetInfo?.phone;
            address = item.spec.targetInfo?.address;
            detailAddress = item.spec.targetInfo?.details ?? '';
          } else if (item.spec.type === '充值') {
            contact = item.spec.phone;
          }
          reportList.push([
            item._id.toHexString(),
            item.spec.type === '商城'
              ? shopInfoData[item.spec.shopId.toHexString()]
                ? shopInfoData[item.spec.shopId.toHexString()].spec.name
                : ''
              : '',
            item.spec.type === '商城' && orderContent.length > 0
              ? orderContent.join('/')
              : '',
            item.spec.type === '商城' ? count : '',
            `${item.spec.type}订单`,
            item.spec.type === '二手'
              ? this.getUsedStatus(item.status as UsedOrderStatus)
              : `${this.getStatus(
                  item.status as
                    | MallOrderStatus
                    | AgentOrderStatus
                    | WithdrawalOrderStatus,
                )}${
                  item.spec.type !== '提现' &&
                  item.spec.type !== '会员卡' &&
                  item.spec.refund
                    ? '(此单含退款)'
                    : ''
                }`,
            status.payInfo ? `${status.payInfo?.payMerchantType}支付` : '',
            item.spec.type === '商城' ? item.spec.receiveInfo?.type : '',
            userInfoData[item.spec.userId.toHexString()]
              ? userInfoData[item.spec.userId.toHexString()].spec.name
              : '',
            contact,
            phone,
            address,
            detailAddress,
            item.spec.type !== '提现' ? item.spec.remarks : '',
            item.spec.type === '商城' && item.spec.amount
              ? Number((item.spec.amount.product / 100).toFixed(2))
              : 0,
            item.spec.type === '商城' &&
            item.spec.amount &&
            item.spec.amount.productCost
              ? Number((item.spec.amount.productCost / 100).toFixed(2))
              : 0,
            item.spec.type === '商城' && item.spec.amount
              ? Number((item.spec.amount.packing / 100).toFixed(2))
              : 0,
            item.spec.type === '商城' && item.spec.amount
              ? Number((item.spec.amount.delivery / 100).toFixed(2))
              : 0,
            item.spec.type === '商城' &&
            item.spec.amount &&
            item.spec.type === '商城' &&
            item.spec.amount
              ? Number(
                  (
                    (item.spec.amount.fullReduction.shop +
                      item.spec.amount.fullReduction.application +
                      item.spec.amount.coupon.shop +
                      item.spec.amount.coupon.application) /
                    100
                  ).toFixed(2),
                )
              : 0,
            item.spec.type === '商城' && item.spec.amount
              ? Number(
                  (
                    (item.spec.amount.coupon.shop +
                      item.spec.amount.coupon.application) /
                    100
                  ).toFixed(2),
                )
              : 0,
            item.spec.type === '商城' && item.spec.amount
              ? Number(
                  (
                    (item.spec.amount.fullReduction.shop +
                      item.spec.amount.fullReduction.application) /
                    100
                  ).toFixed(2),
                )
              : 0,
            item.spec.type === '提现'
              ? Number((item.spec.amount / 100).toFixed(2))
              : item.spec.amount
              ? Number((item.spec.amount.user / 100).toFixed(2))
              : 0,
            item.spec.type === '商城' && item.spec.refund
              ? Number((item.spec.refund.user / 100).toFixed(2))
              : 0,
            expectTime,
            this.getTime(item.metadata.creationTimestamp),
            payTime,
            complete,
          ]);
        });
      }
      const filename = moment().format('YYYY-MM-DD') + '订单.xlsx'; //文件名称
      const wsname = 'Sheet1'; //Excel第一个sheet的名称
      const wb = xlsx.utils.book_new(),
        ws = xlsx.utils.aoa_to_sheet(reportList);
      xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
      xlsx.writeFile(wb, filename); //导出Excel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //跳转售后
  private to_afterSale(id: ObjectId) {
    console.log(id);
    // this.$router.push({
    //   path: '/application/' + this.$route.params.applicationId + '/order/index',
    //   query: {
    //     menu: '售后订单',
    //     type: 'list',
    //     orderId: id.toHexString(),
    //   },
    // });
  }
  //重置
  private resetBtn() {
    this.searchData.id = '';
    this.searchData.status = '';
    this.searchData.type = '';
    this.searchData.deliveryInfo = '';
    this.searchData.payMerchantType = '';
    this.searchData.shopId = '';
    this.searchData.phone = '';
    this.searchData.time = [
      moment().startOf('day').toDate(),
      moment().startOf('day').toDate(),
    ];
    this.time = '今';
  }
}
