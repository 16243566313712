
import {Component, Vue, Watch} from 'vue-property-decorator';
import {
  orders,
  shops,
  applicationOrderRequests,
  users,
  afterSaleOrders,
} from '../../../../resources';
import {ObjectId} from 'bson';
import lodash from 'lodash';
import {
  Order,
  MallOrderStatus,
  AgentOrderStatus,
  WithdrawalOrderStatus,
  Condition,
  UsedProductSnapshot,
  UsedOrderStatus,
  OrderItem,
} from '@/externals/MaxCI-Order-v1';
import moment from 'moment';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {uploadImg} from '../../../../api/publicMethod';
import {AfterSaleOrderItem} from '@/externals/MaxCI-AfterSaleOrder-v1';
import {EJSON} from 'bson';

interface OrderItemNum extends OrderItem {
  quantity: number;
}
@Component({
  name: 'orderdetail',
})
export default class extends Vue {
  private cancelData = {
    reason: '',
    remark: '',
  };
  private afterSaleAll = false; //申请售后全选商品
  private afterSaleStatus = '';
  private action = '';
  private imgData = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private afterSaleGoods: Array<OrderItemNum> = [];
  private afterSaleData = {
    reason: '',
    remark: '',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    images: [] as any,
  };
  private dialogAfterSale = false; //申请售后
  private dialogRequest = false; //取消 退款弹窗
  private requestTitle = '';
  private deliveryStepData: Array<Condition> = [];
  private fullReductionPrice = 0;
  private couponPrice = 0;
  private detailMsg: null | Order = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private stepData: Array<Condition> = []; //订单状态进度条数据
  private stepActive = 100; //当前状态
  private payTime = ''; //支付时间
  private shopMsg: null | Shop = null;
  private usedGoodsData: Array<UsedProductSnapshot> = [];
  private sellerName = ''; //卖家名称
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';
  created() {
    this.checkMsg();
  }
  @Watch('dialogAfterSale')
  private changeDialogAfterSale() {
    if (!this.dialogAfterSale) {
      this.afterSaleData.reason = '';
      this.afterSaleData.remark = '';
      this.afterSaleData.images = [];
      this.afterSaleGoods.forEach(item => {
        item.quantity = 0;
      });
    }
  }
  //申请售后全选商品
  private afterSaleSelect() {
    this.afterSaleGoods.forEach(item => {
      if (this.afterSaleAll) {
        item.quantity = item.count;
      } else {
        item.quantity = 0;
      }
    });
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  private async checkMsg() {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let completeAfterSaleOrders = [] as Array<{
        productId: ObjectId;
        sku: string;
        attributes: Array<string>;
        count: number;
      }>;
      if (this.role === '应用') {
        completeAfterSaleOrders = await afterSaleOrders.find(s =>
          s
            .$match(match => {
              match(
                f => f('spec')('orderId'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              )(
                f => f('status')('phase'),
                e => e.$eq('完成'),
              );
              if (this.role === '店铺' || this.role === '财务') {
                match(
                  f => f('spec')('shopId'),
                  e => e.$in(this.shopAuthority),
                );
              }
              return match;
            })
            .$unwind(f => f('spec')('items'))
            .$group(
              id =>
                id.$object(object =>
                  object('productId', e =>
                    e.$fieldPath(f => f('spec')('items')('productId')),
                  )('sku', e => e.$fieldPath(f => f('spec')('items')('sku')))(
                    'attributes',
                    e => e.$fieldPath(f => f('spec')('items')('attributes')),
                  ),
                ),
              group =>
                group('count', ge =>
                  ge.$sum(e => e.$fieldPath(f => f('spec')('items')('count'))),
                ),
            )
            .$replaceWith(replaceWith =>
              replaceWith.$object(object =>
                object('productId', e =>
                  e.$fieldPath(f => f('_id')('productId')),
                )('sku', e => e.$fieldPath(f => f('_id')('sku')))(
                  'attributes',
                  e => e.$fieldPath(f => f('_id')('attributes')),
                )('count', e => e.$fieldPath(f => f('count'))),
              ),
            ),
        );
      }

      this.detailMsg =
        (
          await orders.find(stage =>
            stage.$match(match => {
              match(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              );
              if (this.role === '店铺' || this.role === '财务') {
                match(
                  f => f('spec')('shopId'),
                  e => e.$in(this.shopAuthority),
                );
              }
              return match;
            }),
          )
        ).find(() => true) ?? null;

      if (this.detailMsg) {
        if (this.role !== '财务') {
          this.checkAfterSale();
        }
        this.stepData = this.detailMsg.status?.conditions ?? [];
        //支付时间
        this.detailMsg.status?.conditions.forEach(item => {
          if (item.type === '支付') {
            this.payTime = this.getTime(item.creationTimestamp);
          }
        });
        if (this.detailMsg.spec.type === '商城') {
          if (this.detailMsg.spec.items) {
            if (this.role !== '财务') {
              let afterSaleGoods = [] as Array<OrderItemNum>;
              if (completeAfterSaleOrders.length > 0) {
                afterSaleGoods = this.detailMsg?.spec?.items
                  .map(v => {
                    const newV = lodash.clone(v);
                    newV.count -=
                      completeAfterSaleOrders.find(
                        val =>
                          v.productId.equals(val.productId) &&
                          v.sku.name === val.sku &&
                          v.attributes.join('/') === val.attributes.join('/'),
                      )?.count ?? 0;
                    return {
                      ...newV,
                      quantity: 0,
                    };
                  })
                  .filter(f => f.count > 0);
              } else {
                afterSaleGoods = this.detailMsg?.spec?.items?.map(v => {
                  return {
                    ...v,
                    quantity: 0,
                  };
                });
              }
              this.afterSaleGoods = afterSaleGoods;
            }
          }

          //满减  优惠券金额 积分
          this.fullReductionPrice =
            ((this.detailMsg.spec.amount?.fullReduction?.shop ?? 0) +
              (this.detailMsg.spec.amount?.fullReduction?.application ?? 0)) /
            100;
          this.couponPrice =
            ((this.detailMsg.spec.amount?.coupon?.shop ?? 0) +
              (this.detailMsg.spec.amount?.coupon?.application ?? 0)) /
            100;
          //物流信息
          this.deliveryStepData =
            (this.detailMsg.status as MallOrderStatus).deliveryInfo
              ?.conditions ?? [];
          //店铺信息
          const shopId = this.detailMsg.spec.shopId;
          this.shopMsg =
            (
              await shops.find(stage =>
                stage.$match(match =>
                  match(
                    f => f('_id'),
                    e => e.$eq(shopId),
                  ),
                ),
              )
            ).find(() => true) ?? null;
        }
        if (this.detailMsg.spec.type === '二手') {
          if (this.detailMsg.spec.snapshot) {
            this.usedGoodsData = [this.detailMsg.spec.snapshot];
          }
          const sellerId = this.detailMsg?.spec.sellerId;
          if (sellerId) {
            this.sellerName =
              (
                await users.find(stage =>
                  stage.$match(match =>
                    match(
                      f => f('_id'),
                      e => e.$eq(sellerId),
                    )(
                      f => f('spec')('applicationId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(
                            this.$route.params.applicationId,
                          ),
                        ),
                    ),
                  ),
                )
              ).find(() => true)?.spec.name ?? '';
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //退款 取消弹窗
  private requestPopUps(type: string) {
    this.requestTitle = type;
    this.dialogRequest = true;
  }
  //退款 取消保存
  private requestSumbit() {
    let reason = '';
    if (!this.cancelData.reason) {
      this.$message.error('请选择取消原因');
      return false;
    }
    if (this.cancelData.reason === '其他原因') {
      if (!this.cancelData.remark) {
        this.$message.error('请填写原因');
        return false;
      } else {
        reason = this.cancelData.remark;
      }
    } else {
      reason = this.cancelData.reason;
    }
    applicationOrderRequests
      .create(
        [
          {
            spec: {
              type: this.requestTitle as '取消' | '退款',
              applicationId: ObjectId.createFromHexString(
                this.$route.params.applicationId,
              ),
              reason: reason,
              orderId: this.detailId,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res[0].status?.phase === '成功') {
          this.$message.success('操作成功');
          this.checkMsg();
          this.dialogRequest = false;
        } else {
          this.$message.error(res[0].status?.conditions[0].message ?? '');
        }
      });
  }
  //跳转用户详情
  private to_user(id: ObjectId) {
    this.$router.push(
      `/application/${ObjectId.createFromHexString(
        this.$route.params.applicationId,
      )}/user/detail/${id.toHexString()}`,
    );
  }
  //查询是否有退款中订单
  private async checkAfterSale() {
    const afterSaleOrder = await afterSaleOrders.find(stage =>
      stage.$match(match => {
        match(
          f => f('spec')('orderId'),
          e => e.$eq(this.detailId),
        )(
          f => f('spec')('applicationId'),
          e =>
            e.$eq(
              ObjectId.createFromHexString(this.$route.params.applicationId),
            ),
        )(
          f => f('status')('phase'),
          e => e.$in(['申请', '通过']),
        );
        if (this.role === '店铺' || this.role === '财务') {
          match(
            f => f('spec')('shopId'),
            e => e.$in(this.shopAuthority),
          );
        }
        return match;
      }),
    );
    if (afterSaleOrder.length > 0) {
      this.afterSaleStatus = '退款中';
    } else {
      this.afterSaleStatus = '退款';
    }
  }
  //申请售后弹窗
  private async afterSalePopup() {
    this.dialogAfterSale = true;
  }
  //申请售后保存
  private afterSaleSumbit() {
    let num = 0;
    const goodsItems = [] as Array<AfterSaleOrderItem>;
    this.afterSaleGoods.forEach(item => {
      if (item.quantity > 0) {
        num = num + 1;
        goodsItems.push({
          amount: 0,
          sku: item.sku.name,
          attributes: item.attributes,
          count: item.quantity,
          productId: item.productId,
        });
      }
    });
    if (num === 0) {
      this.$message.error('请选择退款商品');
      return false;
    }
    if (this.afterSaleData.reason === '') {
      this.$message.error('请选择原因');
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const images = this.afterSaleData.images.map((v: any) => v.response.file);
    if (this.detailMsg?.spec.type === '商城') {
      afterSaleOrders
        .create(
          [
            {
              spec: {
                items: goodsItems,
                reason: this.afterSaleData.reason,
                credentials: images as
                  | []
                  | [string]
                  | [string, string]
                  | [string, string, string],
                remarks: this.afterSaleData.remark,
                shopId: this.detailMsg?.spec.shopId as ObjectId,
                userId: this.detailMsg?.spec.userId as ObjectId,
                channelId: this.detailMsg?.spec.channelId as ObjectId,
                applicationId: ObjectId.createFromHexString(
                  this.$route.params.applicationId,
                ),
                orderId: this.detailId,
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        )
        .then(res => {
          if (res) {
            if (res[0].status?.conditions[0].status) {
              this.$message.success('操作成功');
              this.checkAfterSale();
              this.dialogAfterSale = false;
            } else {
              this.$message.error(res[0].status?.conditions[0].message ?? '');
            }
          }
        });
    }
  }
  //获取状态完成时间
  private getStatusTime(status: string) {
    let time = '';
    this.detailMsg?.status?.conditions.forEach(item => {
      if (item.type === status) {
        time = this.getTime(item.creationTimestamp);
      }
    });
    return time;
  }
  //获取二手订单状态
  private getUsedStatus(status: UsedOrderStatus) {
    let statusText = '';
    if (status.phase === '下单') {
      statusText = '待支付';
    } else if (status.phase === '支付') {
      statusText = '待发货';
    } else if (status.phase === '取消') {
      statusText = '已取消';
    } else if (status.phase === '完成') {
      statusText = '已完成';
    } else if (status.phase === '退款') {
      statusText = '已退款';
    } else {
      statusText = '待收货';
    }
    return statusText;
  }
  //获取订单状态
  private getStatus(
    status: MallOrderStatus | AgentOrderStatus | WithdrawalOrderStatus,
  ) {
    let statusText = '';
    if (status.phase === '确认') {
      statusText = '订单待支付';
    } else if (status.phase === '支付') {
      statusText = '待商家接单';
    } else if (status.phase === '取消') {
      statusText = '订单已取消';
    } else if (status.phase === '完成') {
      statusText = '订单已完成';
    } else if (status.phase === '退款') {
      statusText = '订单已退款';
    } else if (status.phase === '接单') {
      statusText = '商家备货中';
    } else if (status.phase === '打包') {
      statusText = '待用户取餐';
    } else if (status.phase === '配送') {
      statusText = '订单待配送';
    }
    return statusText;
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //查看大图
  private checkimg(url: string) {
    this.$alert(
      '<img style="max-width:100%;max-height:700px;" src="' + url + '">',
      '图片',
      {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
        callback: action => {
          console.log(action);
        },
      },
    );
  }
  //商品属性值显示
  private getAttributes(attr: Array<string>) {
    let val = '';
    if (attr.length > 0) {
      val = '/' + attr.join('/');
    }
    return val;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async beforeImgListUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPG = isJPEG || isPNG; //限制图片格式为jpg / png
    const isLt1M = file.size / 1024 / 1024 < 1; //限制图片大小

    if (!isPG) {
      this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      throw new Error();
    } else if (!isLt1M) {
      this.$message.error('上传图片大小不能超过 1MB!');
      throw new Error();
    } else {
      if (this.platformConfig) {
        const res = await uploadImg(
          this.platformConfig.channels[0].channelId,
          this.platformConfig.applicationId,
          false,
          [],
        );
        if (!res) {
          throw new Error();
        }
        this.action = res.status?.upload?.url ?? '';
        this.imgData = res.status?.upload?.data ?? {};
      } else {
        throw new Error();
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async successImgList(response: any, file: any, fileList: any) {
    this.afterSaleData.images = fileList;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async removeImgList(file: any, fileList: any) {
    this.afterSaleData.images = fileList;
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
