
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import {
  orders,
  applicationOrderRequests,
  wechatUsers,
} from '../../../../resources';
import {ObjectId} from 'bson';
import {Order, WithdrawalOrderStatus} from '@/externals/MaxCI-Order-v1';
import moment from 'moment';
import lodash from 'lodash';

@Component({
  name: 'withdrawOrderList',
  components: {
    multiSelect,
    Pagination,
  },
})
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private wechatUsersData: any = {};
  private reason = ''; //驳回原因
  private dialogCancel = false; //驳回弹窗
  private operateId: null | ObjectId = null;
  private dialogPass = false; //通过弹窗
  private total = 0;
  private list: Array<Order> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private searchData = {
    id: '',
    status: '',
    phone: '',
    time: [] as Array<Date>,
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  //重置
  private resetBtn() {
    this.searchData.id = '';
    this.searchData.status = '';
    this.searchData.phone = '';
    this.searchData.time = [];
  }
  async created() {
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }

    //概况跳转后回显搜索条件
    this.searchData.status = this.$route.query.orderStatus
      ? (this.$route.query.orderStatus as string)
      : '';
    this.updateList();
  }
  //获取openId
  private getOpenId(id: ObjectId) {
    let openId = '--';
    if (id && this.wechatUsersData[id.toHexString()]) {
      openId = this.wechatUsersData[id.toHexString()].spec.openId;
    }
    return openId;
  }
  //获取驳回原因
  private getReason(status: WithdrawalOrderStatus) {
    let reason = '';
    if (status.phase === '取消') {
      status.conditions.forEach(item => {
        if (item.type === '取消') {
          reason = item.message ?? '';
        }
      });
    }
    return reason;
  }
  //驳回弹窗
  private cancelPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogCancel = true;
  }
  //驳回保存
  private cancelSumbit() {
    applicationOrderRequests
      .create(
        [
          {
            spec: {
              type: '取消',
              applicationId: this.applicationId,
              orderId: this.operateId as ObjectId,
              ...(this.reason ? {reason: this.reason} : {}),
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res.length > 0) {
          this.$message.success('操作成功');
          this.updateList();
          this.dialogCancel = false;
        }
      });
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.checkList();
  }
  //通过弹窗
  private passPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogPass = true;
  }
  //通过保存
  private passSumbit() {
    applicationOrderRequests
      .create(
        [
          {
            spec: {
              type: '完成',
              applicationId: this.applicationId,
              orderId: this.operateId as ObjectId,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res.length > 0) {
          this.$message.success('操作成功');
          this.updateList();
          this.dialogPass = false;
        }
      });
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //查看详情
  private toDetail(id: ObjectId) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }

  private async updateList() {
    this.listLoading = true;
    try {
      const list = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('提现'),
                )(
                  f => f('status')('phase'),
                  e => e.$ne('无效'),
                );
                if (this.searchData.id) {
                  query(
                    f => f('_id'),
                    e =>
                      e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                  );
                }
                if (this.searchData.phone) {
                  query(
                    f => f('spec')('userPhone'),
                    e => e.$eq(this.searchData.phone),
                  );
                }
                if (this.searchData.status) {
                  query(
                    f => f('status')('phase'),
                    e =>
                      e.$eq(this.searchData.status as '下单' | '取消' | '完成'),
                  );
                }
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e => e.$gte(moment(this.searchData.time[0]).toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1])
                          .add({day: 1})
                          .subtract({millisecond: 1})
                          .toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      let userIdData: Array<ObjectId> = [];
      list[0].table.forEach(item => {
        userIdData.push(item.spec.userId);
      });
      userIdData = Array.from(new Set(userIdData));
      const userData = await wechatUsers.find(stage =>
        stage.$match(match => {
          match(
            f => f('spec')('userId'),
            e => e.$in(userIdData),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          return match;
        }),
      );
      this.wechatUsersData = lodash.zipObject(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        userData.map(v => v.spec.userId.toHexString()) as any,
        userData,
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //商品属性值显示
  private getAttributes(attr: Array<string>) {
    let val = '';
    if (attr.length > 0) {
      val = '/' + attr.join('/');
    }
    return val;
  }
  //新增
  private addBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'add'},
    });
  }
}
