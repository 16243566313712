
import {Component, Vue, Watch} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import {
  deliveryOrders,
  applicationOrderRequests,
  riderInfos,
  deliveryOrderApplicationRequests,
  deliveryShops,
} from '../../../../resources';
import {ObjectId} from 'bson';
import moment from 'moment';
import {Form} from 'element-ui';
import {
  DeliveryOrder,
  DeliveryOrderStatus,
  InstantDeliveryOrderSpec,
} from '@/externals/MaxCI-DeliveryOrder-v1';
import {RiderInfoSpec} from '@/externals/MaxCI-RiderInfo-v1';
import xlsx from 'xlsx';
import {DeliveryShop} from '@/externals/MaxCI-DeliveryShop-v1';

@Component({
  name: 'deliverOrderList',
  components: {
    multiSelect,
    Pagination,
  },
})
export default class extends Vue {
  private validPhone = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^1[23456789]\d{9}$/.test(value)) {
      callback(new Error('手机号格式不正确'));
    } else {
      callback();
    }
  };
  private formData = {
    phone: '',
  };
  private rules = {
    phone: [
      {required: true, message: '请输入手机号', trigger: 'blur'},
      {validator: this.validPhone, trigger: 'blur'},
    ],
  };
  private deliveryShopData: Array<DeliveryShop> = [];
  private loading = false;
  private riderInfo: null | RiderInfoSpec = null;
  private successStep = 1;
  private dialogSuccess = false; //完成弹窗
  private operateId: null | ObjectId = null;
  private dialogRequest = false; //取消弹窗
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {};
  private total = 0;
  private list: Array<DeliveryOrder> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private time = '今';
  private searchData = {
    id: '',
    outTradeNo: '',
    status: '',
    isToHome: '',
    deliveryName: '',
    shopName: '',
    phone: '',
    time: [moment().startOf('day').toDate(), moment().startOf('day').toDate()],
    isNotice: '',
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  async created() {
    //查询门店列表
    this.deliveryShopData = await deliveryShops.find(stage =>
      stage.$match(match => {
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        );
        return match;
      }),
    );
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }
    //概况跳转后回显搜索条件
    if (this.$route.query.orderStatus) {
      this.searchData.status = this.$route.query.orderStatus
        ? (this.$route.query.orderStatus as string)
        : '';
      if (this.searchData.status) {
        this.searchData.time = [];
      }
    }
    //统计订单跳转后回显搜索条件
    if (this.$route.query.isNotice) {
      this.searchData.isNotice = this.$route.query.isNotice
        ? (this.$route.query.isNotice as string)
        : '';
      this.time = '';
      if (this.searchData.isNotice === '是') {
        if (this.$route.query.startTime && this.$route.query.endTime) {
          this.searchData.time = [
            new Date(this.$route.query.startTime as string),
            new Date(this.$route.query.endTime as string),
          ];
        } else {
          this.searchData.time = [];
        }
      }
    }
    this.updateList();
  }
  @Watch('dialogSuccess')
  private changeDialogSuccess() {
    if (!this.dialogSuccess) {
      this.formData.phone = '';
      this.successStep = 1;
      (this.$refs.formData as Form).clearValidate();
    }
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //下一步
  private async nextBtn() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        const riderInfo = await riderInfos.find(stage =>
          stage.$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('spec')('type'),
              e => e.$eq('骑手'),
            )(
              f => f('spec')('phone'),
              e => e.$eq(this.formData.phone),
            );
            return match;
          }),
        );
        if (riderInfo.length === 0) {
          this.$message.error('该用户不存在');
        } else {
          this.successStep = 2;
          this.riderInfo = riderInfo[0].spec;
        }
      } else {
        return false;
      }
    });
  }
  //完成保存
  private successSumbit() {
    deliveryOrderApplicationRequests
      .create(
        [
          {
            spec: {
              type: '完成',
              applicationId: this.applicationId,
              orderId: this.operateId as ObjectId,
              riderId: (this.riderInfo as RiderInfoSpec).userId,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res[0].status?.conditions[0].status) {
          this.$message.success('操作成功');
          this.updateList();
          this.dialogSuccess = false;
        } else {
          this.$message.error(res[0].status?.conditions[0].message ?? '');
        }
      });
  }
  //完成订单
  private successPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogSuccess = true;
  }
  //取消弹窗
  private requestPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogRequest = true;
  }
  //取消保存
  private requestSumbit() {
    applicationOrderRequests
      .create([
        {
          spec: {
            type: '取消',
            applicationId: this.applicationId,
            orderId: this.operateId as ObjectId,
          },
        },
      ])
      .then(res => {
        if (res.length > 0) {
          this.$message.success('操作成功');
          this.updateList();
          this.dialogRequest = false;
        }
      });
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkList();
  }
  //选择今天明天
  private changeTime() {
    if (this.time === '今') {
      this.searchData.time = [
        moment().startOf('day').toDate(),
        moment().startOf('day').toDate(),
      ];
    } else if (this.time === '昨') {
      this.searchData.time = [
        moment().startOf('day').subtract({day: 1}).toDate(),
        moment().startOf('day').subtract({day: 1}).toDate(),
      ];
    }
    this.checkList();
  }
  //获取订单状态
  private getStatus(status: DeliveryOrderStatus) {
    let statusText = '';
    if (status.phase === '下单') {
      statusText = '待接单';
    } else if (status.phase === '取货') {
      statusText = '待送达';
    } else if (status.phase === '取消') {
      statusText = '已取消';
    } else if (status.phase === '完成') {
      statusText = '已完成';
    } else {
      statusText = '待取货';
    }
    return statusText;
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //查看详情
  private toDetail(id: ObjectId) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await deliveryOrders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                if (this.searchData.id) {
                  query(
                    f => f('_id'),
                    e =>
                      e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                  );
                }
                if (this.searchData.outTradeNo) {
                  query(
                    f => f('spec')('outTradeNo'),
                    e => e.$eq(this.searchData.outTradeNo),
                  );
                }
                if (this.searchData.isNotice === '是') {
                  query(
                    f => f('status')('notice'),
                    e => e.$eq('成功'),
                  );
                } else if (this.searchData.isNotice === '否') {
                  query(
                    f => f('status')('notice'),
                    e => e.$ne('成功'),
                  );
                }
                if (this.searchData.isToHome === '是') {
                  query(
                    f => f('spec')('shopFee')('toHomeFee'),
                    e => e.$ne(0),
                  );
                } else if (this.searchData.isToHome === '否') {
                  query(
                    f => f('spec')('shopFee')('toHomeFee'),
                    e => e.$eq(0),
                  );
                }
                if (this.searchData.deliveryName) {
                  query(
                    f => f('spec')('riderInfo')('name'),
                    e => e.$eq(this.searchData.deliveryName),
                  );
                }
                if (this.searchData.shopName) {
                  query(
                    f => f('spec')('shopInfo')('name'),
                    e => e.$eq(this.searchData.shopName),
                  );
                }
                if (this.searchData.phone) {
                  query(
                    f => f('spec')('receiverInfo')('phone'),
                    e => e.$eq(this.searchData.phone),
                  );
                }
                if (this.searchData.status === '') {
                  query(
                    f => f('status')('phase'),
                    e =>
                      e.$in([
                        '下单',
                        '接单',
                        '分拣',
                        '取货',
                        '到店',
                        '完成',
                        '取消',
                      ]),
                  );
                } else if (this.searchData.status === '待取货') {
                  query(
                    f => f('status')('phase'),
                    e => e.$in(['接单', '分拣', '到店']),
                  );
                } else {
                  query(
                    f => f('status')('phase'),
                    e =>
                      e.$eq(
                        this.searchData.status as
                          | '下单'
                          | '接单'
                          | '分拣'
                          | '到店'
                          | '取货'
                          | '完成'
                          | '无效'
                          | '取消',
                      ),
                  );
                }
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                );
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$gte(
                        moment(this.searchData.time[0])
                          .utcOffset(8)
                          .startOf('day')
                          .toDate(),
                      ),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1])
                          .utcOffset(8)
                          .endOf('day')
                          .toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  // 导出报表
  private async exportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      const count = (
        await deliveryOrders.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  if (this.searchData.id) {
                    query(
                      f => f('_id'),
                      e =>
                        e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                    );
                  }
                  if (this.searchData.outTradeNo) {
                    query(
                      f => f('spec')('outTradeNo'),
                      e => e.$eq(this.searchData.outTradeNo),
                    );
                  }
                  if (this.searchData.isNotice === '是') {
                    query(
                      f => f('status')('notice'),
                      e => e.$eq('成功'),
                    );
                  } else if (this.searchData.isNotice === '否') {
                    query(
                      f => f('status')('notice'),
                      e => e.$ne('成功'),
                    );
                  }
                  if (this.searchData.isToHome === '是') {
                    query(
                      f => f('spec')('shopFee')('toHomeFee'),
                      e => e.$ne(0),
                    );
                  } else if (this.searchData.isToHome === '否') {
                    query(
                      f => f('spec')('shopFee')('toHomeFee'),
                      e => e.$eq(0),
                    );
                  }
                  if (this.searchData.deliveryName) {
                    query(
                      f => f('spec')('riderInfo')('name'),
                      e => e.$eq(this.searchData.deliveryName),
                    );
                  }
                  if (this.searchData.shopName) {
                    query(
                      f => f('spec')('shopInfo')('name'),
                      e => e.$eq(this.searchData.shopName),
                    );
                  }
                  if (this.searchData.phone) {
                    query(
                      f => f('spec')('receiverInfo')('phone'),
                      e => e.$eq(this.searchData.phone),
                    );
                  }
                  if (this.searchData.status === '') {
                    query(
                      f => f('status')('phase'),
                      e =>
                        e.$in([
                          '下单',
                          '接单',
                          '分拣',
                          '取货',
                          '到店',
                          '完成',
                          '取消',
                        ]),
                    );
                  } else if (this.searchData.status === '待取货') {
                    query(
                      f => f('status')('phase'),
                      e => e.$in(['接单', '分拣', '到店']),
                    );
                  } else {
                    query(
                      f => f('status')('phase'),
                      e =>
                        e.$eq(
                          this.searchData.status as
                            | '下单'
                            | '接单'
                            | '分拣'
                            | '到店'
                            | '取货'
                            | '完成'
                            | '无效'
                            | '取消',
                        ),
                    );
                  }
                  query(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  );
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$gte(
                          moment(this.searchData.time[0])
                            .utcOffset(8)
                            .startOf('day')
                            .toDate(),
                        ),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1])
                            .utcOffset(8)
                            .endOf('day')
                            .toDate(),
                        ),
                    ),
                  );
                }
                return and;
              }),
            )
            .$facet(facet =>
              facet('count', countStage => countStage.$count('count')),
            ),
        )
      )[0].count[0];
      if (count) {
        reportList.push([
          '配送订单',
          '订单编号',
          '配送信息',
          '门店名称',
          '收货人',
          '联系方式',
          '收货地址',
          '订单类型',
          '订单状态',
          '基础配送费(￥)',
          '送货上门(￥)',
          '下单时间',
          '接单时间',
          '到店时间',
          '取货时间',
          '完成时间',
          '配送时长(完成时间-接单时间)',
        ]);
        let i = 0;
        while (i < Math.ceil(count.count / 100)) {
          i++;
          const list = await deliveryOrders.find(stage =>
            stage
              .$match(match =>
                match.$and(and => {
                  and(query => {
                    if (this.searchData.id) {
                      query(
                        f => f('_id'),
                        e =>
                          e.$eq(
                            ObjectId.createFromHexString(this.searchData.id),
                          ),
                      );
                    }
                    if (this.searchData.outTradeNo) {
                      query(
                        f => f('spec')('outTradeNo'),
                        e => e.$eq(this.searchData.outTradeNo),
                      );
                    }
                    if (this.searchData.isNotice === '是') {
                      query(
                        f => f('status')('notice'),
                        e => e.$eq('成功'),
                      );
                    } else if (this.searchData.isNotice === '否') {
                      query(
                        f => f('status')('notice'),
                        e => e.$ne('成功'),
                      );
                    }
                    if (this.searchData.isToHome === '是') {
                      query(
                        f => f('spec')('shopFee')('toHomeFee'),
                        e => e.$ne(0),
                      );
                    } else if (this.searchData.isToHome === '否') {
                      query(
                        f => f('spec')('shopFee')('toHomeFee'),
                        e => e.$eq(0),
                      );
                    }
                    if (this.searchData.deliveryName) {
                      query(
                        f => f('spec')('riderInfo')('name'),
                        e => e.$eq(this.searchData.deliveryName),
                      );
                    }
                    if (this.searchData.shopName) {
                      query(
                        f => f('spec')('shopInfo')('name'),
                        e => e.$eq(this.searchData.shopName),
                      );
                    }
                    if (this.searchData.phone) {
                      query(
                        f => f('spec')('receiverInfo')('phone'),
                        e => e.$eq(this.searchData.phone),
                      );
                    }
                    if (this.searchData.status === '') {
                      query(
                        f => f('status')('phase'),
                        e =>
                          e.$in([
                            '下单',
                            '接单',
                            '分拣',
                            '取货',
                            '到店',
                            '完成',
                            '取消',
                          ]),
                      );
                    } else if (this.searchData.status === '待取货') {
                      query(
                        f => f('status')('phase'),
                        e => e.$in(['接单', '分拣', '到店']),
                      );
                    } else {
                      query(
                        f => f('status')('phase'),
                        e =>
                          e.$eq(
                            this.searchData.status as
                              | '下单'
                              | '接单'
                              | '分拣'
                              | '到店'
                              | '取货'
                              | '完成'
                              | '无效'
                              | '取消',
                          ),
                      );
                    }
                    query(
                      f => f('spec')('applicationId'),
                      e => e.$eq(this.applicationId),
                    );
                    return query;
                  });
                  if (this.searchData.time && this.searchData.time.length > 0) {
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e =>
                          e.$gte(
                            moment(this.searchData.time[0])
                              .utcOffset(8)
                              .startOf('day')
                              .toDate(),
                          ),
                      ),
                    );
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e =>
                          e.$lte(
                            moment(this.searchData.time[1])
                              .utcOffset(8)
                              .endOf('day')
                              .toDate(),
                          ),
                      ),
                    );
                  }
                  return and;
                }),
              )
              .$facet(facet =>
                facet('table', tableStage =>
                  tableStage
                    .$sort(sort =>
                      sort(f => f('metadata')('creationTimestamp'), '降序'),
                    )
                    .$skip((i - 1) * 100)
                    .$limit(100),
                )('count', countStage => countStage.$count('count')),
              ),
          );
          list[0].table.forEach(item => {
            //配送信息
            let deliveryInfo = [];
            if (item.spec.sorterInfo) {
              deliveryInfo.push('分拣员：' + item.spec.sorterInfo.name);
            }
            const group = (item.spec as InstantDeliveryOrderSpec).group;
            if (group) {
              deliveryInfo.push('分组：' + group.name);
            }
            if (item.spec.riderInfo) {
              deliveryInfo.push('骑手：' + item.spec.riderInfo.name);
            }
            let orderTime = '',
              acceptanceTime = '',
              arrivalTime = '',
              pickupTime = '',
              completeTime = '',
              deliveryTime = 0; //下单时间，接单时间,到店时间,取货时间,完成时间,配送时间
            if (
              item.spec.type === '即时配送' &&
              item.status &&
              item.status.conditions.length > 0
            ) {
              item.status.conditions.forEach(condition => {
                if (condition.type === '下单') {
                  orderTime = moment(condition.creationTimestamp).format(
                    'YYYY-MM-DD HH:mm:ss',
                  );
                }
                if (condition.type === '接单') {
                  acceptanceTime = moment(condition.creationTimestamp).format(
                    'YYYY-MM-DD HH:mm:ss',
                  );
                }
                if (condition.type === '到店') {
                  arrivalTime = moment(condition.creationTimestamp).format(
                    'YYYY-MM-DD HH:mm:ss',
                  );
                }
                if (condition.type === '取货') {
                  pickupTime = moment(condition.creationTimestamp).format(
                    'YYYY-MM-DD HH:mm:ss',
                  );
                }
                if (condition.type === '完成') {
                  completeTime = moment(condition.creationTimestamp).format(
                    'YYYY-MM-DD HH:mm:ss',
                  );
                }
              });
              if (item.status.phase === '完成') {
                let cTime = null as null | Date,
                  aTime = null as null | Date;
                item.status.conditions.forEach(condition => {
                  if (condition.type === '完成') {
                    cTime = condition.creationTimestamp;
                  }
                  if (condition.type === '接单') {
                    aTime = condition.creationTimestamp;
                  }
                });
                deliveryTime = moment(cTime).diff(aTime, 'minutes');
              }
            }
            reportList.push([
              item._id.toHexString(),
              item.spec.outTradeNo,
              deliveryInfo.join(','),
              item.spec.type === '即时配送' ? item.spec.shopInfo?.name : '',
              item.spec.type === '即时配送' && item.spec.receiverInfo
                ? item.spec.receiverInfo.name
                : '',
              item.spec.receiverInfo ? item.spec.receiverInfo.phone : '',
              item.spec.type === '即时配送' && item.spec.receiverInfo
                ? item.spec.receiverInfo.address
                : '',
              item.spec.type,
              this.getStatus(item.status as DeliveryOrderStatus),
              item.spec.type === '即时配送' && item.spec.shopFee
                ? Number(item.spec.shopFee.baseFee / 100)
                : 0,
              item.spec.type === '即时配送' && item.spec.shopFee
                ? Number(item.spec.shopFee.toHomeFee / 100)
                : 0,
              orderTime,
              acceptanceTime,
              arrivalTime,
              pickupTime,
              completeTime,
              deliveryTime + '分钟',
            ]);
          });
        }
        const filename = moment().format('YYYY-MM-DD') + '订单.xlsx'; //文件名称
        const wsname = 'Sheet1'; //Excel第一个sheet的名称
        const wb = xlsx.utils.book_new(),
          ws = xlsx.utils.aoa_to_sheet(reportList);
        xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
        xlsx.writeFile(wb, filename); //导出Excel
      } else {
        this.loading = false;
        this.$message.error('暂无数据');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //新增
  private addBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'add'},
    });
  }
  //重置
  private resetBtn() {
    this.searchData.id = '';
    this.searchData.outTradeNo = '';
    this.searchData.status = '';
    this.searchData.isToHome = '';
    this.searchData.deliveryName = '';
    this.searchData.shopName = '';
    this.searchData.phone = '';
    this.searchData.time = [
      moment().startOf('day').toDate(),
      moment().startOf('day').toDate(),
    ];
    this.time = '今';
  }
}
