
import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import orderIndex from './components/orderList/index.vue';
import withdrawIndex from './components/withdraw/index.vue';
import deliveryOrderIndex from './components/deliveryOrder/index.vue';
import afterSaleOrderIndex from './components/afterSaleOrder/index.vue';
import {applications} from '../../resources';
import {ObjectId} from 'bson';
@Component({
  name: 'order',
  components: {
    Submenu,
    orderIndex,
    withdrawIndex,
    deliveryOrderIndex,
    afterSaleOrderIndex,
  },
})
export default class extends Vue {
  private mallShow = false;
  private agentShow = false;
  private menu = '';
  private submenu: Array<{
    top: string;
    list: Array<{name: string; active: boolean}>;
  }> = [];
  private role = localStorage.getItem('role');
  async created() {
    if (this.role === '店铺' || this.role === '财务') {
      this.submenu = [
        {
          top: '',
          list: [{name: '订单列表', active: true}],
        },
      ];
      this.menu = this.$route.query.menu
        ? (this.$route.query.menu as string)
        : '订单列表';
    } else {
      const application = (
        await applications.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e =>
                e.$eq(
                  ObjectId.createFromHexString(
                    this.$route.params.applicationId,
                  ),
                ),
            );
            return match;
          }),
        )
      ).find(() => true);
      if (application?.spec.enableServices) {
        application?.spec.enableServices.forEach(item => {
          if (item === '商城' || item === '跑腿') {
            this.mallShow = true;
          }
          if (item === '配送') {
            this.agentShow = true;
          }
        });
      }
      if (this.mallShow && this.agentShow) {
        this.submenu = [
          {
            top: '',
            list: [
              {name: '订单列表', active: true},
              {name: '配送单列表', active: false},
              {name: '提现申请单', active: false},
              {name: '售后订单', active: false},
            ],
          },
        ];
        this.menu = this.$route.query.menu
          ? (this.$route.query.menu as string)
          : '订单列表';
      } else if (!this.mallShow && this.agentShow) {
        this.submenu = [
          {
            top: '',
            list: [
              {name: '配送单列表', active: true},
              {name: '提现申请单', active: false},
              {name: '售后订单', active: false},
            ],
          },
        ];
        this.menu = this.$route.query.menu
          ? (this.$route.query.menu as string)
          : '配送单列表';
      } else if (this.mallShow && !this.agentShow) {
        this.submenu = [
          {
            top: '',
            list: [
              {name: '订单列表', active: true},
              {name: '提现申请单', active: false},
              {name: '售后订单', active: false},
            ],
          },
        ];
        this.menu = this.$route.query.menu
          ? (this.$route.query.menu as string)
          : '订单列表';
      } else if (!this.mallShow && !this.agentShow) {
        this.submenu = [
          {
            top: '',
            list: [
              {name: '提现申请单', active: true},
              {name: '售后订单', active: false},
            ],
          },
        ];
        this.menu = this.$route.query.menu
          ? (this.$route.query.menu as string)
          : '提现申请单';
      }
    }

    this.$store.state.applicationId = this.$route.params.applicationId;
  }
}
