
import {Component, Vue, Watch} from 'vue-property-decorator';
import {
  shops,
  orders,
  afterSaleOrderRequests,
  afterSaleOrders,
} from '../../../../resources';
import {ObjectId} from 'bson';
import {Condition, Order} from '@/externals/MaxCI-Order-v1';
import moment from 'moment';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {
  AfterSaleOrder,
  AfterSaleOrderStatus,
} from '@/externals/MaxCI-AfterSaleOrder-v1';
import {
  AfterSaleOrderRequestRejectSpec,
  AfterSaleOrderRequestPassSpec,
} from '@/externals/MaxCI-AfterSaleOrderRequest-v1';

@Component({
  name: 'afterSaleOrderDetail',
})
export default class extends Vue {
  private reason = ''; //退款原因
  private dialogRequest = false; //取消 退款弹窗
  private requestTitle = '';
  private detailMsg: null | AfterSaleOrder = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private stepData: Array<Condition> = []; //订单状态进度条数据
  private stepActive = 100; //当前状态
  private shopMsg: null | Shop = null;
  private orderMsg: null | Order = null;
  created() {
    this.checkMsg();
  }
  @Watch('dialogRequest')
  private changedialogRequest() {
    if (!this.dialogRequest) {
      this.reason = '';
    }
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  private async checkMsg() {
    try {
      this.detailMsg =
        (
          await afterSaleOrders.find(stage =>
            stage.$match(match =>
              match(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              ),
            ),
          )
        ).find(() => true) ?? null;
      if (this.detailMsg) {
        this.stepData = this.detailMsg.status?.conditions ?? [];
        //店铺信息
        const shopId = this.detailMsg.spec.shopId;
        this.shopMsg =
          (
            await shops.find(stage =>
              stage.$match(match =>
                match(
                  f => f('_id'),
                  e => e.$eq(shopId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              ),
            )
          ).find(() => true) ?? null;

        //订单信息
        const orderId = this.detailMsg.spec.orderId;
        this.orderMsg =
          (
            await orders.find(stage =>
              stage.$match(match =>
                match(
                  f => f('_id'),
                  e => e.$eq(orderId),
                )(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                ),
              ),
            )
          ).find(() => true) ?? null;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //退款 取消弹窗
  private requestPopUps(type: string) {
    this.requestTitle = type;
    this.dialogRequest = true;
  }
  //退款 取消保存
  private requestSumbit() {
    if (this.requestTitle === '同意') {
      this.afterSaleOrderRequest({
        type: '通过',
        applicationId: ObjectId.createFromHexString(
          this.$route.params.applicationId,
        ),
        afterSaleOrderId: this.detailId,
        shopId: this.detailMsg?.spec.shopId as ObjectId,
      });
    } else {
      if (this.reason === '') {
        this.$message.error('请填写原因');
      } else {
        this.afterSaleOrderRequest({
          type: '驳回',
          reason: this.reason,
          applicationId: ObjectId.createFromHexString(
            this.$route.params.applicationId,
          ),
          afterSaleOrderId: this.detailId,
          shopId: this.detailMsg?.spec.shopId as ObjectId,
        });
      }
    }
  }
  private afterSaleOrderRequest(
    data: AfterSaleOrderRequestRejectSpec | AfterSaleOrderRequestPassSpec,
  ) {
    afterSaleOrderRequests
      .create(
        [
          {
            spec: data,
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res) {
          if (res[0].status?.conditions[0].status) {
            this.$message.success('操作成功');
            this.checkMsg();
            this.dialogRequest = false;
          } else {
            this.$message.error(res[0].status?.conditions[0].message ?? '');
          }
        }
      });
  }
  //获取状态完成时间
  private getStatusTime(status: string) {
    let time = '';
    this.detailMsg?.status?.conditions.forEach(item => {
      if (item.type === status) {
        time = this.getTime(item.creationTimestamp);
      }
    });
    return time;
  }
  //获取订单状态
  private getStatus(status: AfterSaleOrderStatus) {
    let statusText = '';
    if (status.phase === '申请') {
      statusText = '申请退款';
    } else {
      statusText = `已${status.phase}`;
    }
    return statusText;
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //查看大图
  private checkimg(url: string) {
    this.$alert(
      '<img style="max-width:100%;max-height:700px;" src="' + url + '">',
      '图片',
      {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
        callback: action => {
          console.log(action);
        },
      },
    );
  }
  //商品属性值显示
  private getAttributes(attr: Array<string>) {
    let val = '';
    if (attr.length > 0) {
      val = '/' + attr.join('/');
    }
    return val;
  }
  //返回
  private bactBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
