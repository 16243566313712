
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import {afterSaleOrders, shops, orders} from '../../../../resources';
import {ObjectId} from 'bson';
import moment from 'moment';
import {
  AfterSaleOrder,
  AfterSaleOrderStatus,
} from '@/externals/MaxCI-AfterSaleOrder-v1';
import xlsx from 'xlsx';
import lodash from 'lodash';

interface OrderShop extends AfterSaleOrder {
  goodsMore: boolean;
}
@Component({
  name: 'afterSaleOrderList',
  components: {
    multiSelect,
    Pagination,
  },
})
export default class extends Vue {
  private loading = false;
  private operateId: null | ObjectId = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {};
  private total = 0;
  private list: Array<OrderShop> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private time = '今';
  private searchData = {
    id: '',
    orderId: '',
    status: '',
    phone: '',
    time: [moment().startOf('day').toDate(), moment().startOf('day').toDate()],
  };
  async created() {
    //回显搜索条件
    if (localStorage.getItem('searchData')) {
      this.searchData = JSON.parse(localStorage.getItem('searchData') ?? '');
      localStorage.setItem('searchData', '');
    }
    //回显分页
    if (localStorage.getItem('listParams')) {
      this.listParams = JSON.parse(localStorage.getItem('listParams') ?? '');
      localStorage.setItem('listParams', '');
    }

    //订单跳转后回显搜索条件
    this.searchData.orderId = this.$route.query.orderId
      ? (this.$route.query.orderId as string)
      : '';
    if (this.searchData.orderId) {
      this.searchData.time = [];
    }
    //概况跳转后回显搜索条件
    this.searchData.status = this.$route.query.orderStatus
      ? (this.$route.query.orderStatus as string)
      : '';
    if (this.$route.query.orderStatus) {
      this.searchData.time = [];
    }
    this.updateList();
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //商品查看更多
  private moreBtn(index: number) {
    this.list[index].goodsMore = !this.list[index].goodsMore;
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.time = '';
    this.checkList();
  }
  //选择今天明天
  private changeTime() {
    if (this.time === '今') {
      this.searchData.time = [
        moment().startOf('day').toDate(),
        moment().startOf('day').toDate(),
      ];
    } else if (this.time === '近七日') {
      this.searchData.time = [
        moment({hour: 0}).subtract({day: 6}).toDate(),
        moment({hour: 0}).toDate(),
      ];
    }
    this.checkList();
  }
  //获取订单状态
  private getStatus(status: AfterSaleOrderStatus) {
    let statusText = '';
    if (status.phase === '申请') {
      statusText = '申请退款';
    } else {
      statusText = `已${status.phase}`;
    }
    return statusText;
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //查看详情
  private toDetail(id: ObjectId) {
    localStorage.setItem('searchData', JSON.stringify(this.searchData));
    localStorage.setItem('listParams', JSON.stringify(this.listParams));
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }

  private async updateList() {
    this.listLoading = true;
    try {
      const list = await afterSaleOrders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                if (this.searchData.id) {
                  query(
                    f => f('_id'),
                    e =>
                      e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                  );
                }
                if (this.searchData.orderId) {
                  query(
                    f => f('spec')('orderId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(this.searchData.orderId),
                      ),
                  );
                }
                if (this.searchData.status === '') {
                  query(
                    f => f('status')('phase'),
                    e => e.$in(['申请', '通过', '驳回', '取消', '完成']),
                  );
                } else {
                  query(
                    f => f('status')('phase'),
                    e =>
                      e.$eq(
                        this.searchData.status as
                          | '申请'
                          | '通过'
                          | '驳回'
                          | '取消'
                          | '完成'
                          | '无效',
                      ),
                  );
                }
                query(
                  f => f('spec')('applicationId'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(
                        this.$route.params.applicationId,
                      ),
                    ),
                );
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e => e.$gte(moment(this.searchData.time[0]).toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1])
                          .add({day: 1})
                          .subtract({millisecond: 1})
                          .toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table.map(v => {
        return {
          ...v,
          goodsMore: false,
        };
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  // 导出报表
  private async exportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      const count = (
        await afterSaleOrders.find(stage =>
          stage
            .$match(match =>
              match.$and(and => {
                and(query => {
                  if (this.searchData.id) {
                    query(
                      f => f('_id'),
                      e =>
                        e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                    );
                  }
                  if (this.searchData.orderId) {
                    query(
                      f => f('spec')('orderId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(this.searchData.orderId),
                        ),
                    );
                  }
                  if (this.searchData.status === '') {
                    query(
                      f => f('status')('phase'),
                      e => e.$in(['申请', '通过', '驳回', '取消', '完成']),
                    );
                  } else {
                    query(
                      f => f('status')('phase'),
                      e =>
                        e.$eq(
                          this.searchData.status as
                            | '申请'
                            | '通过'
                            | '驳回'
                            | '取消'
                            | '完成'
                            | '无效',
                        ),
                    );
                  }
                  query(
                    f => f('spec')('applicationId'),
                    e =>
                      e.$eq(
                        ObjectId.createFromHexString(
                          this.$route.params.applicationId,
                        ),
                      ),
                  );
                  return query;
                });
                if (this.searchData.time && this.searchData.time.length > 0) {
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e => e.$gte(moment(this.searchData.time[0]).toDate()),
                    ),
                  );
                  and(query =>
                    query(
                      f => f('metadata')('creationTimestamp'),
                      e =>
                        e.$lte(
                          moment(this.searchData.time[1])
                            .add({day: 1})
                            .subtract({millisecond: 1})
                            .toDate(),
                        ),
                    ),
                  );
                }
                return and;
              }),
            )
            .$facet(facet =>
              facet('count', countStage => countStage.$count('count')),
            ),
        )
      )[0].count[0];
      if (count) {
        reportList.push([
          '退款单号',
          '关联订单号',
          '商品信息',
          '商户名称',
          '售后数量',
          '退款金额',
          '收货人',
          '联系电话',
          '退款状态',
          '申请时间',
        ]);
        let i = 0;
        while (i < Math.ceil(count.count / 100)) {
          i++;
          const list = await afterSaleOrders.find(stage =>
            stage
              .$match(match =>
                match.$and(and => {
                  and(query => {
                    if (this.searchData.id) {
                      query(
                        f => f('_id'),
                        e =>
                          e.$eq(
                            ObjectId.createFromHexString(this.searchData.id),
                          ),
                      );
                    }
                    if (this.searchData.orderId) {
                      query(
                        f => f('spec')('orderId'),
                        e =>
                          e.$eq(
                            ObjectId.createFromHexString(
                              this.searchData.orderId,
                            ),
                          ),
                      );
                    }
                    if (this.searchData.status === '') {
                      query(
                        f => f('status')('phase'),
                        e => e.$in(['申请', '通过', '驳回', '取消', '完成']),
                      );
                    } else {
                      query(
                        f => f('status')('phase'),
                        e =>
                          e.$eq(
                            this.searchData.status as
                              | '申请'
                              | '通过'
                              | '驳回'
                              | '取消'
                              | '完成'
                              | '无效',
                          ),
                      );
                    }
                    query(
                      f => f('spec')('applicationId'),
                      e =>
                        e.$eq(
                          ObjectId.createFromHexString(
                            this.$route.params.applicationId,
                          ),
                        ),
                    );
                    return query;
                  });
                  if (this.searchData.time && this.searchData.time.length > 0) {
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e => e.$gte(moment(this.searchData.time[0]).toDate()),
                      ),
                    );
                    and(query =>
                      query(
                        f => f('metadata')('creationTimestamp'),
                        e =>
                          e.$lte(
                            moment(this.searchData.time[1])
                              .add({day: 1})
                              .subtract({millisecond: 1})
                              .toDate(),
                          ),
                      ),
                    );
                  }
                  return and;
                }),
              )
              .$facet(facet =>
                facet('table', tableStage =>
                  tableStage
                    .$sort(sort =>
                      sort(f => f('metadata')('creationTimestamp'), '降序'),
                    )
                    .$skip((i - 1) * 100)
                    .$limit(100),
                )('count', countStage => countStage.$count('count')),
              ),
          );
          console.log(list[0].table);
          //获取店铺信息 订单信息
          let shopIdData: Array<ObjectId> = [];
          let orderIdData: Array<ObjectId> = [];
          list[0].table.forEach(item => {
            shopIdData.push(item.spec.shopId);
            orderIdData.push(item.spec.orderId);
          });
          //获取店铺信息
          shopIdData = Array.from(new Set(shopIdData));
          const shopData = await shops.find(stage =>
            stage.$match(match =>
              match(
                f => f('_id'),
                e => e.$in(shopIdData),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              ),
            ),
          );
          const shopInfoData = lodash.zipObject(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            shopData.map(v => v._id.toHexString()) as any,
            shopData,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as any;

          //获取订单信息
          orderIdData = Array.from(new Set(orderIdData));
          const orderData = await orders.find(stage =>
            stage.$match(match =>
              match(
                f => f('_id'),
                e => e.$in(orderIdData),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              ),
            ),
          );
          const orderInfoData = lodash.zipObject(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            orderData.map(v => v._id.toHexString()) as any,
            orderData,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as any;
          list[0].table.forEach(item => {
            //退款数量
            let num = 0;
            item.spec.items.forEach(i => {
              num += i.count;
            });
            reportList.push([
              item._id.toHexString(),
              item.spec.orderId.toHexString(),
              item.spec.items.map(i => `${i.name}`).join('/'),
              shopInfoData[item.spec.shopId.toHexString()]
                ? shopInfoData[item.spec.shopId.toHexString()].spec.name
                : '',
              num,
              item.spec.amount ? Number(item.spec.amount.user / 100) : 0,
              orderInfoData[item.spec.orderId.toHexString()] &&
              orderInfoData[item.spec.orderId.toHexString()].spec
                .deliveryInfo &&
              orderInfoData[item.spec.orderId.toHexString()].spec.deliveryInfo
                .info
                ? orderInfoData[item.spec.orderId.toHexString()].spec
                    .deliveryInfo.info.contact
                : '',
              orderInfoData[item.spec.orderId.toHexString()] &&
              orderInfoData[item.spec.orderId.toHexString()].spec
                .deliveryInfo &&
              orderInfoData[item.spec.orderId.toHexString()].spec.deliveryInfo
                .info
                ? orderInfoData[item.spec.orderId.toHexString()].spec
                    .deliveryInfo.info.phone
                : '',
              this.getStatus(item.status as AfterSaleOrderStatus),
              this.getTime(item.metadata.creationTimestamp),
            ]);
          });
        }
        const filename = moment().format('YYYY-MM-DD') + '订单.xlsx'; //文件名称
        const wsname = 'Sheet1'; //Excel第一个sheet的名称
        const wb = xlsx.utils.book_new(),
          ws = xlsx.utils.aoa_to_sheet(reportList);
        xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
        xlsx.writeFile(wb, filename); //导出Excel
      } else {
        this.loading = false;
        this.$message.error('暂无数据');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
  //查看关联订单
  private checkOrder(id: ObjectId) {
    this.$router.push({
      path: '/application/' + this.$route.params.applicationId + '/order/index',
      query: {
        menu: '订单列表',
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //商品属性值显示
  private getAttributes(attr: Array<string>) {
    let val = '';
    if (attr && attr.length > 0) {
      val = '/' + attr.join('/');
    }
    return val;
  }
  //重置
  private resetBtn() {
    this.searchData.id = '';
    this.searchData.status = '';
    this.searchData.orderId = '';
    this.searchData.phone = '';
    this.searchData.time = [
      moment().startOf('day').toDate(),
      moment().startOf('day').toDate(),
    ];
    this.time = '今';
  }
}
