
import {Component, Vue} from 'vue-property-decorator';
import orderList from './list.vue';
import orderDetail from './detail.vue';
@Component({
  name: 'orderIndex',
  components: {
    orderList,
    orderDetail,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
