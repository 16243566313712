
import {Component, Vue} from 'vue-property-decorator';
import {deliveryOrders} from '../../../../resources';
import {ObjectId} from 'bson';
import {
  DeliveryOrder,
  DeliveryOrderStatus,
} from '@/externals/MaxCI-DeliveryOrder-v1';
import moment from 'moment';

@Component({
  name: 'orderdetail',
})
export default class extends Vue {
  private dialogRequest = false; //取消 退款弹窗
  private requestTitle = '';
  private fullReductionPrice = 0;
  private couponPrice = 0;
  private detailMsg: null | DeliveryOrder = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private stepActive = 100; //当前状态
  private payTime = ''; //支付时间
  created() {
    this.checkMsg();
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  private async checkMsg() {
    try {
      this.detailMsg =
        (
          await deliveryOrders.find(stage =>
            stage.$match(match =>
              match(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              ),
            ),
          )
        ).find(() => true) ?? null;

      if (this.detailMsg) {
        //支付时间
        this.detailMsg.status?.conditions.forEach(item => {
          if (item.type === '支付') {
            this.payTime = this.getTime(item.creationTimestamp);
          }
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //获取订单状态
  private getStatus(status: DeliveryOrderStatus) {
    let statusText = '';
    if (status.phase === '下单') {
      statusText = '待接单';
    } else if (status.phase === '取货') {
      statusText = '待送达';
    } else if (status.phase === '取消') {
      statusText = '已取消';
    } else if (status.phase === '完成') {
      statusText = '已完成';
    } else {
      statusText = '待取货';
    }
    return statusText;
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //返回
  private bactBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
