
import {Component, Vue} from 'vue-property-decorator';
import {orders, applicationOrderRequests} from '../../../../resources';
import {ObjectId} from 'bson';
import {
  Order,
  Condition,
  WithdrawalOrderStatus,
} from '@/externals/MaxCI-Order-v1';
import moment from 'moment';

@Component({
  name: 'orderdetail',
})
export default class extends Vue {
  private reason = ''; //驳回原因
  private dialogCancel = false; //驳回弹窗
  private dialogPass = false; //通过弹窗
  private detailMsg: null | Order = null;
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private stepData: Array<Condition> = []; //订单状态进度条数据
  private stepActive = 100; //当前状态
  created() {
    this.checkMsg();
  }
  private async checkMsg() {
    try {
      this.detailMsg =
        (
          await orders.find(stage =>
            stage.$match(match =>
              match(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('applicationId'),
                e =>
                  e.$eq(
                    ObjectId.createFromHexString(
                      this.$route.params.applicationId,
                    ),
                  ),
              ),
            ),
          )
        ).find(() => true) ?? null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //获取驳回原因
  private getReason(status: WithdrawalOrderStatus) {
    let reason = '';
    if (status.phase === '取消') {
      status.conditions.forEach(item => {
        if (item.type === '取消') {
          reason = item.message ?? '';
        }
      });
    }
    return reason;
  }
  //驳回弹窗
  private cancelPopUps() {
    this.dialogCancel = true;
  }
  //驳回保存
  private cancelSumbit() {
    applicationOrderRequests
      .create(
        [
          {
            spec: {
              type: '取消',
              applicationId: ObjectId.createFromHexString(
                this.$route.params.applicationId,
              ),
              orderId: this.detailId,
              ...(this.reason ? {reason: this.reason} : {}),
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res.length > 0) {
          this.$message.success('操作成功');
          this.checkMsg();
          this.dialogCancel = false;
        }
      });
  }
  //通过弹窗
  private passPopUps() {
    this.dialogPass = true;
  }
  //通过保存
  private passSumbit() {
    applicationOrderRequests
      .create(
        [
          {
            spec: {
              type: '完成',
              applicationId: ObjectId.createFromHexString(
                this.$route.params.applicationId,
              ),
              orderId: this.detailId,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res.length > 0) {
          this.$message.success('操作成功');
          this.checkMsg();
          this.dialogPass = false;
        }
      });
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //返回
  private bactBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
}
